<template>
  <div class="page_wrapper">
    <div class="container shopping">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center mt-5 mb-5">
            <div class="spinner spinner-border" role="status" v-if="spinnerLoading">
              <span class="visually-hidden"></span>
            </div>
          </div>
          <!--BraintreePayment v-if="paymentType === 'braintree'"/-->
          <template v-if="paymentType === 'stripe'">
          <h4 class="widget-title">Payment details</h4>
          <StripePayment />
          </template>
          <template v-else>
          <BankPayment v-if="paymentType === 'bank'"/>
          </template>
          <!--todo: view per bonifico andato a buon fine  -->
          <!--PaypalPayment v-if="paymentType === 'paypal'" :order="this.order"/-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import BraintreePayment from "@/components/BraintreePayment.vue";
import PaypalPayment from "@/components/PaypalPayment.vue";
import {etoken} from "@/constants/constants.js";
import StripePayment from "@/components/StripePayment.vue";
import BankPayment from "@/components/BankPayment.vue";


export default {
  name: "CheckoutPayment",
  components : {
    BankPayment,
    StripePayment,
    BraintreePayment,
    PaypalPayment
  },
  data() {
    return {
      idorder : this.$route.params.idorder,
      paymentType: null,
      order : {}
    }
  },
  mounted(){
    //verifico che l'ordine non sia già stato pagato
    this.getOrder();
  },
  computed:{
    spinnerLoading() {
      return this.$store.getters.getStatusSpinnerLoading;
    },
  },
  methods:{
    async getOrder(){
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      await axios.get("orders/" + this.idorder,{
        headers: { Authorization: `Bearer ${token}`, 'e-token': etoken }
      }).then((resp) => {
        if(resp.data.order.type === 'website') {
          if (resp.data.order.status === 'pending') {
            this.paymentType = resp.data.order.payment.type;
            this.order = resp.data.order;
          } else {
            router.push("/message/6");
          }
        }else{
          router.push("/");
        }
        this.$store.commit('hideSpinner');
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        console.log(error);
        router.push("/");
      });
    }
  }
}
</script>

<style scoped>

</style>