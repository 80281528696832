<template>
  <div class="section">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-3 col-md-6">
          <img class="logo-main" :src="'/images/bottiglia.png'" style="max-height: 77px;">
          <h5> Provenienza garantita</h5>
          <p class="text">Acquistiamo direttamente dai produttori, per evitare possibili truffe o
            alterazioni
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <img class="logo-main" :src="'/images/botte.png'" style="max-height: 77px;">
          <h5> Stoccaggio Climatizzato</h5>
          <p class="text"> I nostri prodotti vengono conservati alla temperatura perfetta per preservarne
            le caratteristiche
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <img class="corriere-img logo-main" :src="'/images/corriere.png'" style="max-height: 77px;">
          <h5> Spedizione in 24/48h</h5>
          <p class="text"> Garantiamo che il tuo ordine partirà dalla nostra sede entro 48 ore dalla
            ricezione della richiesta
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <img class="logo-main" :src="'/images/pagamenti.png'" style="max-height: 77px;">
          <h5> Pagamenti Sicuri</h5>
          <p class="text"> Utilizziamo i migliori sistemi di pagamento, per tutelare i nostri clienti e noi
            stessi
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowBanner",
  data() {
    return {}
  },
}
</script>

<style scoped>
.section{
  background-color: #f5efe6;
  padding: 50px;
}
@media (max-width: 767.98px) {
  .text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* Overlay che copre tutta la sezione */
.background-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Contenitore con sfondo e overlay */
.background-overlay {
  position: relative;
  padding: 100px 20px;
  background-image: url("/images/tappi_app-1067x800-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
  z-index: 0;
}

/* Il testo deve stare sopra l'overlay */
.background-overlay .display-5 {
  position: relative;
  z-index: 2;
}

/* Stili per i pulsanti */
.btn-brand {
  color: white;
  background-color: #f0c57a;
  border-radius: 10px;
  border-color: #f0c57a;
  padding: 20px 40px !important;
  margin-top: 20px;
}

.btn-brand:hover {
  color: black;
  background-color: white !important;
  border-radius: 10px;
  border-color: #f0c57a;
  padding: 20px 40px !important;
}
h5{
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ba9457;
}
.corriere-img{
  margin-bottom: 13px;
}
</style>
