<template>
  <div class="section-contacts">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="mt-3 mb-4">
            Contattaci
          </h1>
          <p>Siamo a tua disposizione per rispondere a qualsiasi richiesta o domanda. Non esitare a scriverci.</p>
          <div class="row">
            <div class="col-lg-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#744333" width="35" viewBox="0 0 384 512">
                <path
                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
              </svg>
              <h3 class="mt-3 mb-4">
                Indirizzo
              </h3>
              <p>Via Francesco Giordani, 32
                80122 Napoli NA
                info@vinoecioccolato.it
                081 761 8478</p>
            </div>
            <div class="col-lg-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#744333" width="40" viewBox="0 0 512 512">
                <path
                    d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/>
              </svg>
              <h3 class="mt-3 mb-4">
               Orari di apertura
              </h3>
              <p>Lunedì-Sabato<br>
                09:30–13:30, 16:30–20:00<br>
                Domenica 10:30–13:30</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <form>
            <h1 class="mt-3 mb-5">Richiedi informazioni</h1>
            <div class="form-group">
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="La tua email">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Il tuo nome">
            </div>
            <div class="form-group">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                        placeholder="Messaggio"></textarea>
            </div>
            <button type="submit" class="btn btn-brand mt-20">Invia</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowContacts",
}

</script>

<style scoped>

.section-contacts {
  position: relative;
  /*background-color: #f0c57a;*/
  padding: 20px 0;
}

.container {
   bottom: 0;
  left: 0;
  right: 0;
  height:100%;
  background-color: white;
 /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  z-index: 1;
}

.form-group input, .form-group textarea {
  border-radius: 10px;
  border: none;
  background-color: #f7f7f7;
}

/* Stili per i pulsanti */
.btn-brand {
  color: white;
  background-color: #f0c57a;
  border-radius: 10px;
  border-color: #f0c57a;
  padding: 10px 30px !important;
  margin-top: 20px;
}

.btn-brand:hover {
  color: black;
  background-color: white !important;
  border-radius: 10px;
  border-color: #f0c57a;
  padding: 10px 30px !important;
}
</style>